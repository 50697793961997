import { plansorchestrator, PlatformApiError } from '@tabeo/platform-api-client'
import { getJWT } from '@tabeo/sharpei/utils/jwt'
import refreshAccessToken from 'utils/refreshAccessToken'

export const authMiddleware = {
  pre: context => {
    return new Promise(resolve => {
      resolve({
        ...context,
        init: {
          ...context.init,
          headers: {
            ...context.init?.headers,
            Authorization: `Bearer ${getJWT('token')}`,
          },
        },
      })
    })
  },
  post: async context => {
    if (
      context.response?.status === 401 &&
      !context.url.includes('/login') &&
      !context.url.includes('/refresh')
    ) {
      const headers = new Headers(context.init.headers)

      if (!headers.has('retry')) {
        await refreshAccessToken()
        return context.fetch(context.url, {
          ...context.init,
          headers: {
            ...context.init.headers,
            retry: 'true',
          },
        })
      }

      return Promise.reject(context.response)
    }

    return context.response
  },
} satisfies plansorchestrator.Middleware

export const errorMiddleware = {
  post: async context => {
    if (!context.response.ok) {
      const response = await context.response.json()
      return Promise.reject(new PlatformApiError(response))
    }
  },
} satisfies plansorchestrator.Middleware
