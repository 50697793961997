import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import { Modal, usePromise, View } from '@tabeo/scarf'
import { Button, Notification } from '@tabeo/scarf2'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSubscriptionV2 } from 'resources/SubscriptionV2'

export type CancelModalProps = React.ComponentProps<typeof Modal>

const CancelModal = React.forwardRef(
  ({ ...rest }: CancelModalProps, ref: any) => {
    const { t } = useTranslation()
    const { data, resource } = useSubscriptionV2()
    const [trigger, { pending }] = usePromise(async () => {
      if (resource?.cancel) {
        await resource.cancel()
      }
    })

    const benefits = [
      data?.subscription?.treatments?.map(({ treatment, usages }) => ({
        name: treatment?.name,
        quantity: treatment?.quantity,
        used: usages?.reduce((acc, curr) => acc + (curr?.quantity || 0), 0),
      })),
      data?.subscription?.perks?.map(({ perk, usages }) => ({
        name: perk?.name,
        quantity: perk?.quantity,
        used: usages?.reduce((acc, curr) => acc + (curr?.quantity || 0), 0),
      })),
    ]
      .flat()
      .filter(Boolean) as {
      name: string
      quantity?: number
      used: number
    }[]

    return (
      <Modal ref={ref}>
        {({ isOpen, close }) => {
          return isOpen ? (
            <View
              maxWidth={['90%', '620px']}
              mx="auto"
              bg="white"
              borderRadius={1}
              boxShadow={1}
              p={[5, 10]}
              className="space-y-5"
              {...rest}
            >
              <h3 className="title">
                {t('Do you want to cancel this membership?')}
              </h3>
              <Notification variant="warning">
                <ExclamationTriangleIcon />
                {t(
                  'Cancelling this plan will end the membership immediately, and no further payments will be taken. The customer will no longer be able to claim membership benefits from this point forward.'
                )}
              </Notification>
              <div>
                {t('Please review the customer’s usage before continuing:')}
              </div>
              <ul className="space-y-1">
                {benefits.map(b => (
                  <li
                    key={b.name}
                    className="caption flex justify-between gap-4 px-2"
                  >
                    <span className="text-tabeo-ink-2">{b.name}</span>
                    <div className="text-tabeo-ink-0">
                      {b.quantity
                        ? `${b.used} / ${b.quantity}`
                        : t('Unlimited')}
                    </div>
                  </li>
                ))}
              </ul>
              <div className="space-y-4">
                <Button
                  variant="primary"
                  className="w-full"
                  loading={pending}
                  onClick={trigger}
                >
                  {t('Cancel membership')}
                </Button>
                <Button variant="secondary" className="w-full" onClick={close}>
                  {t('Close')}
                </Button>
              </div>
            </View>
          ) : null
        }}
      </Modal>
    )
  }
)

export default CancelModal
